<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class=" primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard
        </v-chip> <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'Products' }">Products
        </v-chip>
      </v-chip-group>
    </div>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-py-2 md:tw-py-5">
      <card-title icon="/images/icons/top-icon.png">Choose which product you wish to quote for</card-title>
      <div class="tw-w-full px-2 md:px-4 tw-py-4 md:tw-py-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-2 md:tw-gap-6">
        <v-card v-for="(p, i) in products" :key="i" v-show="p.display" class="px-2 md:px-4 tw-flex tw-justify-center tw-flex-col tw-text-center tw-py-1 md:tw-py-3 tw-mt-4 md:tw-mt-0" elevation="2">
          <v-icon class="c-gray-text tw-absolute tw-self-end px-2 tw-cursor-pointer" size="20">mdi-help-circle</v-icon>
          <img class="tw-w-10 tw-w-12 tw-self-center" :src="p.icon" alt="icon">
          <h5 class="title-font c-blue-text text-xl tw-py-0">{{p.title}}</h5>
          <p class="c-gray-text tw-text-xs">{{p.desc}}</p>
          <v-btn outlined color="primary" :to="p.link" class="w-20 tw-self-center">Get Quote</v-btn>
        </v-card>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../layouts/Default';
import CardTitle from '../components/CardTitle';
import { allProducts } from '@/utils/products';

export default {
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'products_page_visited_by_agent', {
        event_category: 'Products page component created',
        event_label: 'products component created',
      });
    }
  },
  mounted() {
    this.$store.commit('quote/setSaved', false);
  },
  name: 'Dashboard',
  metaInfo: {
    title: 'Products',
  },
  components: { CardTitle, Default },
  data() {
    return {
      products: allProducts(),
    };
  },
};
</script>
